/*COMPONENTE WALLET    #EFEDE1*/
.comp-wallet {
    background-color: #fff;
    width: 100vw !important;
    max-width: 1440px;
    margin-bottom: 0px;
}
/*TOP BUTTONS*/
.buttons-my-wallet{
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    margin-bottom: 20px;
    align-items: center;
}
.btn-add-wallet{
    background:white;
    cursor: pointer;
    border:solid 2px green;
    font-size: 16px;
    padding: 5px 30px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    background:green;
    color: white;
    margin: 5px;
    width: 300px;
}

.btn-add-wallet:hover{
    color:green;
    background:white;
    border:solid 2px green;
}
/*AVISO*/
.aviso{
    background-color: #000000;
    height: 35px;
    width: 180px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    border-radius: 10px;
}

/*TOP TITLE*/
.comp-wallet-title{
    margin: 0 40px;
    padding: 10px 10px;
    background-color: #000000;
    border-radius: 20px;
    color: #fff;
    display:grid;
    grid-template-columns: 3% 15% 10% 15% 10% 10% 10% 12% 12% 3%;
}
.comp-wallet-title.pop-title{
    grid-template-columns: 3% 23% 23% 23% 23% 3% !important;
}
.border-left{
    display: flex;
    justify-content: center;
}
.border-left-2{
    border-left: solid 2px #b3b3b3;
    display: flex;
    justify-content: center;
}
.div-title-star{
    display: flex;
    justify-content: center;
}

/*MY WALLETS*/

.comp-my-wallet{
    margin: 10px 0;
    padding: 0px 0px;
    background-color: #f2f2f2;
    border-radius: 20px;
    color: black;
    display:grid;
    grid-template-columns: 4% 15% 10% 13% 10% 10% 10% 12% 12% 4%;
    width: 100%;
}
.comp-my-wallet.wallet-pop{
    grid-template-columns: 3% 23% 23% 23% 23% 3% !important;
}
.my-wallet-scroll{
    background-color: #f2f2f222;
    max-height: 420px;
    padding: 0 0;
    margin: 0 40px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
    min-height: auto;
    width: 100%;
    max-width: 100%;
}
.my-wallet-scroll.cargando:before{
    content: "CARGANDO WALLETS";
    width: 100%;
    max-width: calc(1440px - 80px);
    height: 380px;
    background-color: white;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #00000055;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.div-wallet-star{
    display: flex;
    justify-content: center;
    align-items: center;
}
.favorite{
    border: none;
    height: 35px;
    cursor: pointer;
}
.div-wallet-star .star-icon{
    color: black;
    font-size: 30px;

}

.favorite:hover .star-icon{
    color: white;
}


.div-wallet-star .span-wallet-id{
    position: relative;
    top: -22px;
    color:white;
    font-weight: 600;
    font-size: 14px;
}
.favorite:hover .span-wallet-id{
    color: black;
    font-weight: bold;
}

.div-wallet-crypto{
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    column-gap: 5px;
    align-items: center;
}
.cripto-name{
    font-weight: 500;
    color: #3f4765;
}

.cripto-sufijo{
    font-weight: 500;
    color: gray;
}
.div-wallet-current{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    text-align: right;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}
.div-wallet-ath{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    text-align: right;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}
.div-wallet-high{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
}
.div-wallet-date{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}
.div-wallet-drop{
    display: flex;
    justify-content: center;
    padding-right: 10px;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}
.div-wallet-total{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    text-align: right;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: green;
}
.div-wallet-del{
    display: flex;
    justify-content:center;
    align-items: center;
    
}
.buton-del{
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.fa-trash{
    font-size: 30px;
    color:black;
}
.fa-trash:hover{
    color:red;
    cursor: pointer;
}

.trash-icon{
    text-align: center;
    width: 100%;
    font-size: 30px !important;
    color:black;
    
}

.div-wallet-tokens{
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit-button{
    position: relative;
    margin-left: -25px;
}
.input-tokens-wallet{
    height: 30px;
    text-align: right;
    padding-right: 30px;
    width: 70%;
    border:solid 1px #00000055;
    border-radius:10px;
    font-size: 16px;
    font-weight: bold;


}

.input-tokens-wallet:disabled{
    height: 20px;
    text-align: right;
    padding-right: 30px;
    width: 70%;
    border:none;
    
}
.div-wallet-drop.grafica{
    width: 100%;
}


/*COMP TOTAL*/
.comp-total{
    max-width: 1440px;
    margin: 0 40px;
    padding: 10px 0px;
    text-align: right;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
    align-items: center;
}
.comp-total span{
    font-size: 22px;
    color:black;
    font-weight: 500;
}
.comp-total b{
    font-size: 40px;
    color:#008100;
    font-weight: 500;
}

/*COMP SAVE*/
.comp-save{
    margin: 0 40px;
    text-align: center;
}




/*MY WALLETS POPUP*/

.pop-add {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    background: #000000aa;
    justify-content: center;
    align-items: center;
}
.pop-add-content{
    display: flex;
    flex-direction: column;
    background: #fff;
    height: 700px;
    max-height: 700px;
    align-items: center;
    margin: 0 60px;
    border-radius: 20px;
    padding: 10px;
}


.pop-exit{
    position: absolute;
    right: 40px;
    background-color: transparent;
    border: solid 1px black;
    height: 30px;
    width: 30px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 100%;
    cursor: pointer;
}
.pop-exit:hover{
    background-color: red;
    color:white;
    border:none
}

/*comp-add-wallet*/
.comp-add-wallet{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin:0 80px;
    border: solid 2px #00000055;
    border-radius: 20px;
    padding: 20px;
    align-items: center;
}
.token-add{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.search-name-add-wallet{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
}
.select-add-wallet{
    width: 100%;
   text-align: right;
}
.add-img-wallet{
    position: relative;
    margin-right: -25px;
    z-index: 1;
}
.add-token-div{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.token-title{
    position: relative;
    margin-right: -45px;
    z-index: 1;
}
.token-input-add{
    text-align: right;
    height: 30px;
    width: 100%;
}
.button-add{
    height: 35px;
    width: 20%;
    background:linear-gradient(45deg,#80c480,  #008100 );
    border-radius: 10px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
.button-add:hover{
    height: 35px;
    width: 20%;
    background:white;
    border-radius: 10px;
    border: solid 1px #008100 ;
    color: #008100 ;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
.my-wallets-zoom{
    transform: scale(0.9);
    width: 100%;
}
.my-wallets-zoom .comp-wallet-title{
    width: 100%;
}
.my-wallet-scroll.add-comp{
    max-height: 200px !important;
    
}

.my-wallet-scroll.WalletPop{
 width: 100%;
 margin: 0;
 margin-left: 50px;
 margin-right: 50px;
 max-width: calc(100% - 100px) !important;
}


/*nuevos css*/

.amw-pop{
    display: flex;
    opacity: 1;
    position: fixed;
    top:0;
    left:0;
    right: auto;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    justify-content: center;
    align-items: flex-start;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background: #00000099;
    transition: opacity linear 1s;
}

.amw-pop-content{
    background: #ffffff;
    border-radius: 10px;
    max-width:  calc(1440px * 0.95);
    max-height: 90vh;
    width: 95%;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
}
.amw-pop-content .comp-title{
    height: 90px;

}
.amw-pop-content-row-1{
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    padding-top: 10px;
    height: auto;
    margin-bottom: 10px;
    align-items: center;
}

.btn-close-pop{
    height: 50px;
    border-radius: 50%;
    font-weight: 900;
    color: red;
    border:solid 2px red;
    background: white;
}
.btn-close-pop:hover{
    color: white;
    border:solid 2px red;
    background: red;
    cursor: pointer;
}


/*ROW 2 */

.amw-pop-content-row-2{
    display: flex;
    justify-content: flex-start;
    padding-right: 50px;
    padding-left: 50px;
    height: auto;
    margin-bottom: 10px;
    align-items: center;
}
.comp-add-wallet{
    width: 100%;
    margin: 0;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #aaaaaa33;
    border:none;
}
.comp-add-wallet .token-add{
    align-items: flex-end;
}

.token-add-col-1{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.token-add-search{
    display: flex;
    width: 100%;
    align-items: center;
}
.select-add-wallet div{
    padding-left: 10px;
}



.token-add-col-2{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.add-token-amount{
    display: flex;
    align-items: center;
    font-size: 16px;
}
.add-token-amount .token-input-add{
    font-size: 18px;
    font-weight: 500;
    border-color: #00000033;
    border-width: 1px;
    border-radius: 5px;
    height: 35px;
}



.token-add-col-3{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.token-add-col-3 .button-add{
    width: 100%;
    height: 39px;
}

.amw-pop-content-row-3{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    width: 92%;
    max-height: 50vh;
    min-height: 50vh;
}
.amw-pop-content-row-3 .comp-wallet-title{
    display: grid;
    margin: 0;
    width: 99%;
    grid-template-columns: 4% 26% 18% 18% 10% 20% 4% !important;
    align-items: center;
}
.amw-pop-content-row-3 .my-wallet-scroll{
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    max-height: 42vh !important;
    min-height: 42vh !important;
    max-width: 100% !important;
}
.amw-pop-content-row-3 .comp-my-wallet{
    display: grid;
    margin: 0 !important;
    margin-top: 5px !important;
    width: 99%;
    grid-template-columns: 4% 26% 18% 18% 10% 20% 4% !important;
    align-items: center;
    padding: 5px;
}

.amw-pop-content-row-3 .fa-trash{
    font-size: 30px;
}
.amw-pop-content-row-3 .fa-trash:hover{
    color:red;
    cursor: pointer;
}

.amw-pop-content-row-4{
    display: grid;
    grid-template-columns: 35% 40%;
    grid-template-rows: 100%;
    justify-content: space-between;
    height: 70px;
    max-height: 70px;
    min-height: 50px;
    background-color: white;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.amw-pop-content-row-4 .comp-total {
    padding: 0;
    margin: 0;
    margin-right: 50px !important;
}
.amw-pop-content-row-4 .comp-save{
    height: auto !important;
    margin: 0 !important;
}
.cripto-names.completo{
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    
}
.cripto-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.div-wallet-crypto {
    padding-right: 10px;
}

/* Tooltip container */
    
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    display: none;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 10;
    margin-top:20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 0;
    height: 0;
    
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    width: auto;
    height: auto;
    display: flex;
  }