/*RESPONSIVE PHONE MAX WIDTH 420PX*/
@media only screen and (max-width:480px){

    /*COMPONENTE CALCULATOR*/
    .comp-calculator{
        max-width: 420px;
        height: 250px;
        margin-bottom: 250px;
    }
    .section-calculator{
        margin: 0 20px;
        padding: 20px;
        border-radius: 10px;
        border:solid 2px #00000055;
        box-shadow: 0px 0px 20px 2px #00000055;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .column-calculator{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 10px;
        width: auto;
        height: 50px;
    }
    

    .calc-inner2{
        display: flex;
        justify-content: space-between;
    }

    .column-calculator > span{
        font-weight: bold;
        font-size: 16px;
        width: auto;
    }
    /*BUSCADOR CALCULATOR*/
    div.calc-search{
        column-gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;

    }
    .search-calculator{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

    }
    .search-calculator > img{
        position: relative;
        margin-right: -25px;
        z-index: 1;
    }
    .select-calculator{
        width: 100% !important;
        text-align: right;
    }
    /*se necesita darle padding al input*/


    /*EDITOR USD CALCULATOR*/
    div.calc-usd{
        column-gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .calc-i{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 65%;
        margin-right: 15px;
    }

    .dollar-calculator{
        position: relative;
        margin-right: -25px;
    }

    .edit-calculator{
        position: relative;
        margin-left: -25px;
        color: #00000099;
    }
    .calc-input{
        height: 30px;
        border:solid 1px #00000022;
        border-radius: 10px;
        width: 100%;
        padding-left: 30px;
        font-size: 20px;
        font-weight: 600;
        color:#00000099;
        
    }

    /*TOKENS CALCULATOR*/
    div.calc-tokens{
        column-gap: 20px;
        width: 100%;
        justify-content: space-between;
        
    }
    span.calc-token{
        font-size: 24px;
        
    }

    /*ATH CALCULATOR*/
    div.calc-ath{
        column-gap: 20px;
        width: 100%;
        justify-content: space-between;
    }
    span.calc-ath{
        font-size: 24px;
    }
    /*TOTAL CALCULATOR*/
    div.calc-total{
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        border-top:solid 1px #00000022;
        padding-top: 20px;
    }
    span.calc-total{
        font-size: 34px;
        color:#008100;
        font-weight: bold;
        text-align: right;
        width: 100%;
    }
    
    .select-calculator{
        width: 220px !important;
        text-align: right;
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }
    .column-calculator.calc-search{
        justify-content: space-between !important;
        width: 100% !important;

    }
    .search-calculator{
        justify-content: flex-end;
    }
}