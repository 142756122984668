/*RESPONSIVE PHONE MAX WIDTH 420PX*/
@media only screen and (max-width:480px){

/*GLOBAL*/

.page-session{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    background-color: white;
    height: calc(100vh - 80px);
    max-width: 1440px;
}
.form-session{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
    max-width:890px;
    height: 50vh;
    min-height: 400px;
    max-height:500px;
    border-radius: 20px;
    box-shadow: 1px 1px 50px 20px #00000099;
    flex-direction: column;
}
.form-session-right{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color: white;
    border-radius: 20px;
    background: 
        linear-gradient(320deg, 
            var(--c-tercero) 0%, 
            var(--c-cuarto) 50%, 
            var(--c-quinto) 100%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.form-session-left{
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    justify-content: center;
    padding-bottom: 20px;
}
.form-session-inputs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
}
.session-input{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.session-input input{
    height: 30px;
    margin-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 15px;
    border: solid 2px #00000011;
    width: 60%;

}
.session-icon-left{
    color: green;
    margin-right:-25px;
    margin-bottom:10px;
    position: relative ;
    
}
.session-icon-right{
    margin-left:-25px;
    margin-bottom:10px;
    position: relative ;
    color: green;
    width: 20px;
    text-align: center;
}
.session-icon-right.hidden{
    visibility: hidden;
}
.session-form-remember{
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    font-size: 14px;
    row-gap: 10px;
}
.check-remember{
    display: flex;
    align-items: center;

}
.btn-session-login{
    width: 80%;
    background: 
        linear-gradient(320deg, 
            var(--c-tercero) 0%, 
            var(--c-cuarto) 60%, 
            var(--c-quinto) 100%);
    border-radius: 15px;
    border:solid 1px #00000011;
    height: 30px;
    color:white;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;

}
.form-session-left > h3{
    font-size: 29px;
    font-weight: 500;
}
.session-token-text{
    width: 70%;
    text-align: center;
}
.session-send-right{
    margin-left:-90px;
    padding-bottom:15px;
    position: relative ;
    color:  var(--c-quinto);
    text-align: right;
    font-weight: bold;
    width: 80px;
}
.form-session-right{
    display: flex;
    justify-content: center;
    row-gap: 20px;
}

.form-session-left{
    min-width: 100%;
}
.form-session-right{
    min-width: 100%;
}


}
/*RESPONSIVE DESKTOP MAX WIDTH 420PX*/
@media only screen and (min-width:480px){
}