/*RESPONSIVE PHONE MAX WIDTH 420PX*/


@media only screen and (max-width:480px){
    /*COMPONENTE WALLET    #EFEDE1*/
    .comp-wallet {
        background-color: #fff;
        width: 100vw !important;
        max-width: 480px;
        margin-bottom: 100px;
    }
    /*TOP BUTTONS*/
    .buttons-my-wallet{
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: flex-start;
        margin-bottom: 20px;
    }
    .btn-section-1{
        width: 100%;
        height: 120px;
    }
    .btn-section-2{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;
    }
    /*AVISO*/
    .aviso{
        background-color: #000000;
        height: 40px;
        width: 180px;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
        

    /*MY WALLETS*/

    .comp-my-wallet{
        padding: 10px 0px;
        background-color: white;
        border-radius: 20px;
        color: black;
        display:flex;
        width: 90%;
        border: solid 2px #00000055;
        margin-top: 10px;
        margin-right: 20px;
        margin-left: 20px;
        min-height: 350px !important;
        flex-direction: column;

    }
    .comp-my-wallet.minimized{
        min-height: 120px !important;
    }
    .my-wallet-scroll{
        background-color: #efede1;
        max-height: 500px;
        padding: 0;
        margin: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .more{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
    .div-wallet-inner{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .section-1-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;
    }
    .section-2-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 10px;
        border-bottom: solid 1px gray;
        padding-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .section-3-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        border-bottom: solid 1px gray;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .minimized .section-2-wallet{
        border-bottom: none ;
    }
    .section-4-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        border-bottom: solid 1px gray;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .section-5-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        border-bottom: solid 1px gray;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .section-6-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }


    .div-wallet-star{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .favorite{
        border: none;
        height: 35px;
        cursor: pointer;
    }
    .div-wallet-star .star-icon{
        color: #008100;
        font-size: 50px;
    }
    .div-wallet-star .span-wallet-id{
        position: relative;
        top: 0;
        color:white;
        font-weight: 600;
        font-size: 24px;
        left: -35px;
    }
    .favorite:hover .span-wallet-id{
        color: black;
        font-weight: bold;
    }

    .div-wallet-crypto{
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
        column-gap: 5px;
        align-items: flex-start;
        flex-direction: column;
    }
    .cripto-name{
        font-weight: 500;
        color: #008100;
    }
    .inner-wallet-crypto{
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
        column-gap: 5px;
        align-items: center;
        width: 50%;
    }
    .cripto-sufijo{
        font-weight: 500;
        color: #008100;
    }
    .div-wallet-current{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-right: 10px;
        text-align: right;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        width: 50%;
        align-items: flex-start;
    }
    .current-value{
        font-size: 24px;
    }
    .div-wallet-ath{
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        text-align: right;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
    }
    .div-wallet-high{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-weight: bold;
        font-size: 22px;
    }
    .div-wallet-date{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
    }
    .div-wallet-drop{
        display: flex;
        justify-content: center;
        padding-right: 10px;
        text-align: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
    }
    .div-wallet-total{
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        text-align: right;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        color: #000;
        flex-direction: column;
    }
    .div-wallet-total b{
        color: #008100 !important;
        font-size: 26px;
    }
    .div-wallet-del{
        display: flex;
        justify-content:center;
        align-items: center;
        
    }
    .buton-del{
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .trash-icon{
        text-align: center;
        width: 100%;
        font-size: 22px;
        color:black;
    }
    .trash-icon:hover{
        cursor: pointer;
        color:red;
    }
    .btn-add-wallet{
        height: 100px;
        
    }
    .div-wallet-tokens{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .div-wallet-tokens.minimizado{
        width: 100%;
    }
    .div-wallet-inner span{
        font-weight: bold;
        font-size: 16px;
        color: #000;
    }
    .edit-button{
        position: relative;
        margin-left: -25px;
    }
    .input-tokens-wallet{
        height: 40px;
        text-align: left;
        max-width: 100px;
        border:solid 1px #00000055;
        border-radius:10px;
        font-size: 22px;
        font-weight: bold;
        padding-right: 30px;
        width: 100%;
    }

    .input-tokens-wallet:disabled{
        height: 40px;
        text-align: left;
        padding-right: 30px;
        border:none;
        background-color: white;
        
    }
    .div-wallet-drop.grafica{
        width: 100%;
    }
    .edit-icon{
        color: #008100;
        font-size: 24px;
    }

    /*COMP TOTAL*/
    .comp-total{
        max-width: 1440px;
        margin: 0 40px;
        padding: 10px 0px;
        text-align: right;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
        column-gap: 30px;
        align-items: center;
    }
    .comp-total span{
        font-size: 22px;
        color:black;
        font-weight: 500;
    }
    .comp-total b{
        font-size: 40px;
        color:#008100;
        font-weight: 500;
    }

    /*COMP SAVE*/
    .comp-save{
        margin: 0 40px;
        text-align: center;
    }




    /*MY WALLETS POPUP*/

    .pop-add {
        width: 100vw;
        height: 100vh;
        display: flex;
        position: fixed;
        z-index: 10001;
        top: 0;
        left: 0;
        background: #00000055;
        justify-content: center;
        align-items: center;
    }
    .pop-add-content{
        display: flex;
        flex-direction: column;
        background: #fff;
        max-height: 60vh;
        align-items: center;
        margin: 0 60px;
        border-radius: 20px;
        padding: 10px;
    }


    .pop-exit{
        position: absolute;
        right: 40px;
        background-color: transparent;
        border: solid 1px black;
        height: 30px;
        width: 30px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        border-radius: 100%;
        cursor: pointer;
    }

    /*comp-add-wallet*/
    .comp-add-wallet{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin:0 80px;
        border: solid 2px #00000055;
        border-radius: 20px;
        padding: 20px;
        align-items: center;
    }
    .token-add{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .search-name-add-wallet{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 40%;
    }
    .select-add-wallet{
        width: 100%;
    text-align: right;
    }
    .add-img-wallet{
        position: relative;
        margin-right: -25px;
        z-index: 1;
    }
    .add-token-div{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .token-title{
        position: relative;
        margin-right: -45px;
        z-index: 1;
    }
    .token-input-add{
        text-align: right;
        height: 30px;
        width: 100%;
    }
    .button-add{
        height: 35px;
        width: 20%;
        background:linear-gradient(45deg,#80c480,  #008100 );
        border-radius: 10px;
        border: none;
        color: white;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
    }
    .button-add:hover{
        height: 35px;
        width: 20%;
        background:white;
        border-radius: 10px;
        border: solid 1px #008100 ;
        color: #008100 ;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
    }
    .my-wallets-zoom{
        transform: scale(0.9);
        width: 100%;
    }
    .my-wallets-zoom .comp-wallet-title{
        width: 100%;
    }
    .my-wallet-scroll.add-comp{
        max-height: 200px !important;
        
    }


}