@media only screen and (max-width:480px){
    .comp-title{
        width: 100vw !important;
        max-width: 480px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .section-title{
        margin: 0px 20px;
        margin-top: 20px;
        font-weight: 600;
        font-size: 24px;
        text-transform: capitalize;
    }
    .section-title img{
        margin-right: 10px;
    }
    .section-subtitle{
        margin: 0px 20px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}