
.comp-since-ath{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    margin: 40px 0px;
    width: 100vw;
    max-width: 1440px;
}

.div-sinces{
    width: 95%;
    display:grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    justify-content: space-between;
    margin: 0 40px;
}
.element-ranking{
    background-color: #fff ;
    display: flex;
    width:100%;
    justify-content: flex-start;
    margin-bottom: 10px;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    column-gap: 10px;
}

.element-ranking::before{
    content: "";
    width: 3px;
    height: 70%;
    top: 15%;
    position: relative;
    background-color: #00000022;
    left: 0;
}
.element-ranking:nth-child(0n){
    background-color: #00000011;
}
.selected-since{
    margin: 10px;
    height: 30px;
    background-color: #008100;
    color: white;
    font-size: 20px;
    border: solid 2px #00000022;
    cursor: pointer;
}
.no-selected-since{
    margin: 10px;
    height: 30px;
    background-color: #fff;
    color: #00000066;
    font-size: 20px;
    border: solid 1px #00000066;
    cursor: pointer;
}
.div-since-buttons{
    margin-bottom: 20px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
}
.button-load-more{
    margin: 10px;
    height: 30px;
    background-color: #00000033;
    color: black;
    font-size: 18px;
    font-weight: 500;
    border: solid 1px #00000033;
    cursor: pointer;
}

.load-more-section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
}
.last-update{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    column-gap: 10px;
    height: 50px;
    width: 95%;
}



.logo-name{
    display: flex;
    flex-direction: column;
    width: 10%;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 30px;
}
.derecha{
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.abajo{
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
}
.price-value{
    color:green;
    font-weight: bold;
    font-size: 12px;
}
.ath-value{
    color:black;
    font-weight: bold;
    font-size: 12px;
}
.arriba{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}


.since-x{
    color:#00000099;
    font-weight: bold;
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 10px;
}

.high-porcent span{
    color:black;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 30px;
}
.high-porcent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    align-items: flex-start;
    height: 100%;
}


.porcent{
    height: 5px;
    width: 100%;
    background-color: white;

}

.porcent .line{
    height: 4px;
    background: linear-gradient(90deg,red 0%,rgb(88, 24, 22) 90%,black 100%);
}
.porcent .circle{
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    margin-top: -10px;
    
}
.porcent .inner-circle{
    position: relative;
    height: 7px !important;
    width: 7px !important;
    border-radius: 50%;
    background-color: white;
    margin-top: 0px;
    margin-left: 0%;
}
.go-bottom{
    height: 50px;
    width: 50px;
    background-color: green;
    position: fixed;
    bottom: 75px;
    right: 20px;
    z-index: 1000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}













.element-ranking:nth-child(4),
.element-ranking:nth-child(5),
.element-ranking:nth-child(6){
    background-color:#00000022;
}
.element-ranking:nth-child(10),
.element-ranking:nth-child(11),
.element-ranking:nth-child(12){
    background-color:#00000022;
}
.element-ranking:nth-child(16),
.element-ranking:nth-child(17),
.element-ranking:nth-child(18){
    background-color:#00000022;
}
.element-ranking:nth-child(22),
.element-ranking:nth-child(23),
.element-ranking:nth-child(24){
    background-color:#00000022;
}

.element-ranking:nth-child(28),
.element-ranking:nth-child(29),
.element-ranking:nth-child(30){
    background-color:#00000022;
}
.element-ranking:nth-child(34),
.element-ranking:nth-child(35),
.element-ranking:nth-child(36){
    background-color:#00000022;
}
.element-ranking:nth-child(40),
.element-ranking:nth-child(41),
.element-ranking:nth-child(42){
    background-color:#00000022;
}
.element-ranking:nth-child(46),
.element-ranking:nth-child(47),
.element-ranking:nth-child(48){
    background-color:#00000022;
}

.element-ranking:nth-child(1):before,
.element-ranking:nth-child(4):before,
.element-ranking:nth-child(7):before,
.element-ranking:nth-child(10):before,
.element-ranking:nth-child(13):before,
.element-ranking:nth-child(16):before,
.element-ranking:nth-child(19):before,
.element-ranking:nth-child(22):before,
.element-ranking:nth-child(25):before,
.element-ranking:nth-child(28):before,
.element-ranking:nth-child(31):before,
.element-ranking:nth-child(34):before,
.element-ranking:nth-child(37):before,
.element-ranking:nth-child(40):before,
.element-ranking:nth-child(43):before,
.element-ranking:nth-child(46):before,
.element-ranking:nth-child(49):before,
.element-ranking:nth-child(52):before,
.element-ranking:nth-child(55):before,
.element-ranking:nth-child(58):before,
.element-ranking:nth-child(61):before{
    display: none;
}