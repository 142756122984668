
/*GENERALES*/

.comp-header{
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
    background: var(--c-tercero) !important;
    background: linear-gradient(90deg, var(--c-tercero) 0%, var(--c-cuarto) 48%, var(--c-quinto) 100%);    
    color:white ;
    width: 100vw;
    min-height: 60px;
    background: var(--c-white-1);
    box-shadow:0px 0px 5px 0.2px var(--c-black-1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw !important;
    z-index: 10000;
    margin: 0px auto;
    min-height: 60px;
    max-width: 1440px;
    
}
.beta{
    background: #bdbfc1;
    color:#000;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    padding: 0;
    top:60px;
    left: auto;
    right: auto;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw !important;
    max-width: 1440px;
    z-index: 9000;
}
.div-right-header{
    display: flex;
    align-items: center;
    column-gap: 20px;
    cursor: pointer;
}

/*MENU*/
.main-menu-link > span{
    color:white;
    text-decoration: none;
}
.main-menu-link svg{
    color:white
}

.comp-header .main-menu-link{
text-decoration: none;
font-size: 16px;
margin-right: 10px;
margin-right: 10px;
color: var(--c-black-1);
font-weight: 500;
}
.comp-header .main-menu-link:hover{
font-weight: bold;
}

/*LOGIN*/
.login-menu-link{
    display: flex;
    align-items: center !important;
    justify-content: center;
    column-gap: 10px;
    font-weight: 500;
}
.comp-header .main-menu-link.link{
    color:white;
    display: flex;
    align-items: center !important;
    justify-content: center;
    column-gap: 10px;
    font-weight: 500;
    font-family: 'Calibri',sans-serif;
}
.comp-header .main-menu-link.link:hover{
    color:black;
}
.comp-header nav{
    display:flex;
    column-gap: 20px;
}

/*USER*/

.div-login{
    display: flex;
    column-gap: 10px;
}
.login-menu-link > span{
    color:white;
    
}
.div-login a{
    text-decoration: none !important;
}
.div-right-header span.link-separator{
    height: 25px;
    border-left: solid 1px white;
}
.div-right-header .div-login{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.div-right-header div[id$=listbox]{
    color:black;
    font-weight: bold;
}
.img-login{
    height: 30px;
    width: 30px;
    background: black;
    border-radius: 100%;
    border:solid 1px white;
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    color: white;
    
}
.login-menu-link:hover{
    color: white !important;
}
.link-lang .select-lang div[class$=control]{
    background: transparent;
    border: none;
    cursor: pointer;
}
.link-lang .select-lang div[class$=singleValue]{
    color:white !important;
}
.link-lang .select-lang div[class$=control] div[class$=IndicatorsContainer] span[class$=indicatorSeparator]{
    display:none;
}
.link-lang .select-lang div[class$=control] div[class$=IndicatorsContainer] div{
    color:white !important;
}


/*BUSQUEDA */
.comp-header .Search-Bar{
    height: 25px;
    padding-left: 35px;
    background-color: transparent;
    border: solid 1px white;
    color:white !important;
    border-radius: 5px;
}
.Search-Bar::placeholder{
    color:white !important;
}
.comp-header .fa-search-icon{
    position: relative;
    z-index: 0;
    margin-right: -25px;
}





/* DARK MODE */

.mode{
    position: fixed;
    height: 20px;
    width: 80px;
    background-color: white;
    right: 50px;
    top: 15px;
    border-radius: 20px;
    color:black;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    transition: linear 5s;
    cursor: pointer;
    border: solid 1px black;
}
.mode.dark{
    background-color: black;
    color:white;
    justify-content: flex-start;
    border: solid 1px white;
}
.mode .span{
    color:white;
}
.light:before{
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: black;
    border-radius: 100%;
    z-index: 10;
    left: 0;
}
.dark:before{
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    right: 0;
    background-color: white;
    border-radius: 100%;
    z-index: 10;
}
.Dark{
   
}
.Light{
   
}