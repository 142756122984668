.form-privacy-policy{
    margin: 0px 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
}
.privacy-back-to-home{
    position: fixed;
    bottom: 0px;
    width:  calc(100%);
    background-color: white;
    padding-top: 0px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -0px -1px 50px 0.5px #00000055;
    z-index: 100;
    max-width: 1440px !important;
}
.privacy-back-to-home a {
    text-decoration: none;
}
#root > div{
    max-width: 1440px !important;
  }
@media only screen and (max-width:480px) {
    .form-privacy-policy{
        margin: 0px 20px;
       
    }
    .privacy-back-to-home{
        width:  calc(100%);
        padding-top: 0px;
        padding-bottom: 10px;
        text-decoration: none;
    }
        
}
