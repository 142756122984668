.form-cookies{
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    width: 100%;
    max-width: 1440px !important;
    
}
#root > div{
  max-width: 1440px !important;
}
.cookies-back-to-home{
    position: fixed;
    bottom: 0px;
    width:  100%;
    background-color: white;
    padding-top: 0px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -0px -1px 50px 0.5px #00000055;
    z-index: 100;
    max-width: 1440px !important;
}
.cookies-back-to-home a {
    text-decoration: none;
}
.cookies-table{
    
    margin-left: 100px;
    margin-right: 100px;
}
.cookies-table thead td{
    border-left: 1px solid green !important;
    border-right: 1px solid green !important;
    border-bottom: 1px solid green !important;
    border-top: 1px solid green !important;
    max-width: 200px !important;
}
.cookies-table tbody td{
    border-left: 1px solid green !important;
    border-right: 1px solid green !important;
    border-bottom: 1px solid green !important;
}

@media only screen and (max-width:480px) {
    .cookies-back-to-home{
        width:  calc(100%);
        padding-top: 0px;
        padding-bottom: 10px;
        text-decoration: none;
        
    }
    .form-cookies{
        padding-top: 100px;
        padding-bottom: 100px;
        text-align: center;
        width: 100vw !important;
        max-width: 100vw !important;

    }
    .cookies-table{
    
        margin-left: 10px;
        margin-right: 10px;
    }
}