
/*RESPONSIVE PHONE MAX WIDTH 420PX*/
@media only screen and (max-width:480px){

    #footer{
        background-color: #4d4d4d;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        max-width: 1440px;
    }
    #footer a{
        text-decoration: none;
        color:white;
    }
    .parrafo-01{
        font-size: 12px;
    }

    .footer-row.row1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        width: 100%;
        padding-top: 20px;
        text-align: center;
    }



    .footer-inner-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px;
        margin-bottom: 10px;
        width: 100%;
    }
    .footer-column .social-media{
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 10px;
        cursor: pointer;
        
    }
    .footer-column .social-media img{
        opacity: 0.8;
    }

    .footer-column .social-media img:hover{
        opacity:1 ;
    }

    .footer-column{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 20px;
    }

    .footer-column.column-1{
        width: 50%;
        max-width: 640px;
    }
    .footer-column.column-3{
        width: auto;
        max-width: 320px ;
    }
    .footer-column.column-4{
        width:100%;
        max-width: 100% ;
    }

    /*ROW 2 FOOTER*/
    .footer-row.row2{
        display: flex;
        justify-content:center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        flex-direction: column;
        row-gap: 10px;
    }

    .footer-row.row2 span{
        margin-left: 35px;
    }

    .botones-descarga{
        display: flex;
        column-gap: 20px;
    }
    
    .footer-column.column-1{
        width: 80%;
        margin: 0 20px;
        line-height: 20px;
    }
}