/*RESPONSIVE PHONE MAX WIDTH 420PX*/

@media only screen and (max-width:480px){
    /*COMPONENTE WALLET    #EFEDE1*/
    .comp-wallet {
        background-color: #fff;
        width: 100vw !important;
        max-width: 480px;
        margin-bottom: 100px;
    }
    /*TOP BUTTONS*/
    .buttons-my-wallet{
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .btn-section-1{
        width: 100%;
        height: 80px;
    }
    .btn-section-0{
        width: 100%;
        height: 0px;
    }
    .btn-section-2{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 60px;
    }
    .btn-section-1 .btn-add-wallet{
        width: 98%;
        height: 50px;
        margin-bottom: 0px;
    }
    .comp-wallet-title{
        display: none;
    }
    /*AVISO*/
    .aviso{
        background-color: #000000;
        height: 40px;
        width: 180px;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
        

    /*MY WALLETS*/

    .comp-my-wallet{
        padding: 10px 0px;
        background-color: white;
        border-radius: 20px;
        color: black;
        display:flex;
        width: 90%;
        border: solid 2px #00000055;
        margin-top: 10px;
        margin-right: 20px;
        margin-left: 20px;
        min-height: 350px !important;
        flex-direction: column;

    }
    .comp-my-wallet.minimized{
        min-height: 120px !important;
    }
    .my-wallet-scroll{
        background-color: #efede1;
        max-height: 500px;
        padding: 0;
        margin: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .more{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
    .div-wallet-inner{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .section-1-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;
    }
    .section-2-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 10px;
        border-bottom: solid 1px gray;
        padding-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .section-3-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        border-bottom: solid 1px gray;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .minimized .section-2-wallet{
        border-bottom: none ;
    }
    .section-4-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        border-bottom: solid 1px gray;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .section-5-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        border-bottom: solid 1px gray;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .section-6-wallet{
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
        margin-top: 5px;
        padding-bottom: 5px;
        margin-left: 10px;
        margin-right: 10px;
    }


    .div-wallet-star{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .favorite{
        border: none;
        height: 35px;
        cursor: pointer;
    }
    .div-wallet-star .star-icon{
        color: #008100;
        font-size: 50px;
    }
    .div-wallet-star .span-wallet-id{
        position: relative;
        top: 0;
        color:white;
        font-weight: 600;
        font-size: 24px;
        left: -35px;
    }
    .favorite:hover .span-wallet-id{
        color: black;
        font-weight: bold;
    }

    .div-wallet-crypto{
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
        column-gap: 5px;
        align-items: flex-start;
        flex-direction: column;
    }
    .cripto-name{
        font-weight: 500;
        color: #008100;
    }
    .inner-wallet-crypto{
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
        column-gap: 5px;
        align-items: center;
        width: 50%;
    }
    .cripto-sufijo{
        font-weight: 500;
        color: #008100;
    }
    .div-wallet-current{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-right: 10px;
        text-align: right;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        width: 50%;
        align-items: flex-start;
    }
    .current-value{
        font-size: 24px;
    }
    .div-wallet-ath{
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        text-align: right;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
    }
    .div-wallet-high{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-weight: bold;
        font-size: 22px;
    }
    .div-wallet-date{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
    }
    .div-wallet-drop{
        display: flex;
        justify-content: center;
        padding-right: 10px;
        text-align: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
    }
    .div-wallet-total{
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        text-align: right;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        color: #000;
        flex-direction: column;
    }
    .div-wallet-total b{
        color: #008100 !important;
        font-size: 26px;
    }
    .div-wallet-del{
        display: flex;
        justify-content:center;
        align-items: center;
        
    }
    .buton-del{
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .trash-icon{
        text-align: center;
        width: 100%;
        font-size: 22px;
        color:black;
    }
    .trash-icon:hover{
        cursor: pointer;
        color:red;
    }
    .btn-add-wallet{
        height: 100px;
        
    }
    .div-wallet-tokens{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .div-wallet-tokens.minimizado{
        width: 100%;
    }
    .div-wallet-inner span{
        font-weight: bold;
        font-size: 16px;
        color: #000;
    }
    .edit-button{
        position: relative;
        margin-left: -25px;
    }
    .input-tokens-wallet{
        height: 40px;
        text-align: left;
        max-width: 100px;
        border:solid 1px #00000055;
        border-radius:10px;
        font-size: 22px;
        font-weight: bold;
        padding-right: 30px;
        width: 100%;
    }

    .input-tokens-wallet:disabled{
        height: 40px;
        text-align: left;
        padding-right: 30px;
        border:none;
        background-color: white;
        
    }
    .div-wallet-drop.grafica{
        width: 100%;
    }
    .edit-icon{
        color: #008100;
        font-size: 24px;
    }

    /*COMP TOTAL*/
    .comp-total{
        max-width: 1440px;
        margin: 0 40px;
        padding: 10px 0px;
        text-align: right;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
        column-gap: 30px;
        align-items: center;
    }
    .comp-total span{
        font-size: 22px;
        color:black;
        font-weight: 500;
    }
    .comp-total b{
        font-size: 40px;
        color:#008100;
        font-weight: 500;
    }

    /*COMP SAVE*/
    .comp-save{
        margin: 0 40px;
        text-align: center;
    }




    /*MY WALLETS POPUP*/

    .pop-add {
        width: 100vw;
        height: 100vh;
        display: flex;
        position: fixed;
        z-index: 10001;
        top: 0;
        left: 0;
        background: #00000055;
        justify-content: center;
        align-items: center;
    }
    .pop-add-content{
        display: flex;
        flex-direction: column;
        background: #fff;
        max-height: 60vh;
        align-items: center;
        margin: 0 60px;
        border-radius: 20px;
        padding: 10px;
    }


    .pop-exit{
        position: absolute;
        right: 40px;
        background-color: transparent;
        border: solid 1px black;
        height: 30px;
        width: 30px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        border-radius: 100%;
        cursor: pointer;
    }

    /*comp-add-wallet*/
    .comp-add-wallet{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin:0 80px;
        border: solid 2px #00000055;
        border-radius: 20px;
        padding: 20px;
        align-items: center;
    }
    .token-add{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .search-name-add-wallet{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 40%;
    }
    .select-add-wallet{
        width: 100%;
        text-align: right;
        z-index: 1000;
    }
    .add-img-wallet{
        position: relative;
        margin-right: -25px;
        z-index: 1;
    }
    .add-token-div{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .token-title{
        position: relative;
        margin-right: -45px;
        z-index: 1;
    }
    .token-input-add{
        text-align: right;
        height: 30px;
        width: 100%;
    }
    .button-add{
        height: 35px;
        width: 20%;
        background:linear-gradient(45deg,#80c480,  #008100 );
        border-radius: 10px;
        border: none;
        color: white;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
    }
    .button-add:hover{
        height: 35px;
        width: 20%;
        background:white;
        border-radius: 10px;
        border: solid 1px #008100 ;
        color: #008100 ;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
    }
    .my-wallets-zoom{
        transform: scale(0.9);
        width: 100%;
    }
    .my-wallets-zoom .comp-wallet-title{
        width: 100%;
    }
    .my-wallet-scroll.add-comp{
        max-height: 200px !important;
        
    }
    .mobile-section-1{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color: white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        align-items: center;
        max-height: 50px !important;
    }
    .mobile-section-2{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color:white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        align-items: center;
        max-height: 50px !important;
        border-top: solid 2px #88888655;
        padding-top: 5px;
    }

    .mobile-section-1.expanded{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color: white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        align-items: center;
    }
    .mobile-section-2.expanded{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color: white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        max-height: 50px !important;
        align-items: center;
        border-top: none;
        padding-top: 5px;
    }
    .mobile-section-3{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color: white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        align-items: center;
        border-bottom: solid 2px #88888655;
    }
    .mobile-section-4{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color: white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        align-items: center;
        border-bottom: solid 2px #88888655;
    }
    .mobile-section-5{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color: white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        align-items: center;
        border-bottom: solid 2px #88888655;
    }
    .mobile-section-6{
        display: flex !important;
        width:  calc(100% - 40px) !important;
        background-color: white !important;
        justify-content: space-between !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        min-height: 50px !important;
        align-items: center;
    }


    .btn-star{
        height: 40px !important;
        width: 40px !important;
        font-size: 40px !important;
        color: green !important;
        background-color: #fff !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    .btn-star p{
        height: 40px !important;
        width: 40px !important;
        color: green !important;
        font-size: 40px !important;
        position: relative !important;
        z-index: 1 !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .btn-star p:before{
        margin-right: -24px;
    }
    .btn-star span{
        color: #fff !important;
        font-size: 15px !important;
        font-weight: 400;
        text-align: left !important;
        position: relative !important;
        z-index: 10 !important;
        margin-right: 16px;
        text-shadow: 2px 2px black;
    }

    .btn-expand{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border:solid 1px #00000055;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(90deg) !important;
    }
    .btn-expand.expanded{
        transform: rotate(0deg) !important;
    }


    .div-wallet-crypto {
        max-height: 50px !important;
        display: flex;
        border: none;
        font-weight: 500;
        font-size: 14px !important;
        color:#888886;
    }
    .div-names-crypto{
        display: flex;
        font-size: 14px !important;
        align-items: center;
    }
    .div-names-crypto img{
       height: 25px;
       width: 25px;
       margin-right: 10px;
    }
    .div-names-crypto .cripto-name{
        font-size: 16px !important;
        margin-right: 5px;
    }
    .div-names-crypto .cripto-sufijo{
        font-size: 16px !important;
        display: flex;
    }

    .div-wallet-crypto.expanded .div-names-crypto img{
        height: 20px;
        width: 20px;
        margin-right: 5px;
    }
    .div-wallet-crypto.expanded .div-names-crypto .cripto-name{
        font-size: 14px !important;
        margin-right: 2px;
    }
    .div-wallet-crypto.expanded .div-names-crypto .cripto-sufijo{
        font-size: 12px !important;
    }

    .div-wallet-current.expanded {
        text-align: right !important;
        display: flex;
        justify-content: flex-end;
        width: 50% !important;
        border:none;
    }

    .div-wallet-crypto.expanded{
        width: 70% !important;
        border-right: solid 2px #88888655;
    }
    .div-wallet-current.expanded span{
        text-align: right !important;
        justify-content: flex-end;
        width: 100% !important;

    }
    .title-mobile-current{
        font-weight: 500;
        font-size: 14px !important;
        color:#888886;
    }
    .mobile-section-2.expanded{
        border-bottom: solid 2px #88888655;
        padding-bottom: 2px;
        padding-top: 2px;
    }

    .mobile-section-2.mobile-5 .div-wallet-crypto {
        width: 95% !important;
    }

    .myWallet .comp-total{
        flex-direction: column;

    }

    .aviso{
        position: relative;
        margin: 0 !important;
        padding: 0 !important;
        width: 100%;
    }
    .btn-section-0.withAviso{
        height: 50px;
        margin: 0 !important;
    }
    .btn-section-1{
        height: 60px;
    }
    
    .comp-wallet{
        margin-bottom: 20px;
    }

    .comp-selector-swap .comp-selector-swap{
       display: flex;
       justify-content: center;
       width: 100% !important;
    }
    .comp-selector-swap .swap-input{
        width: auto!important;
        padding-right: 10px;
        width: 54% !important;
    }
    .comp-selector-swap{
        margin: 0;
        display: flex;
        justify-content: center;
    }
























        
    /*nuevos css*/
    .my-wallet-scroll.WalletPop{
        width: 100%;
        margin: 0;
        margin-left: 10px;
        margin-right: 10px;
        max-width: calc(100% - 20px) !important;
    }



    .amw-pop{
        display: flex;
        opacity: 1;
        position: fixed;
        top:0;
        left:0;
        right: auto;
        width: 100vw;
        height: 100vh;
        z-index: 10000;
        justify-content: center;
        align-items: flex-start;
        padding-top: 5vh;
        padding-bottom: 5vh;
        background: #00000099;
        transition: opacity linear 1s;
    }

    .amw-pop-content{
        background: #ffffff;
        border-radius: 10px;
        max-width:  calc(1440px * 0.95);
        max-height: 90vh;
        width: 95%;
        height: 100%;
        margin-left: 10px;
        margin-right: 10px;
    }
    .amw-pop-content .comp-title{
        height: 90px;
    }
    .amw-pop-content-row-1{
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        padding-top: 0px;
        height: auto;
        margin-bottom: 10px;
        align-items: center;
    }

    .btn-close-pop{
        height: 50px;
        border-radius: 50%;
        font-weight: 900;
        color: red;
        border:solid 2px red;
        background: white;
    }
    .btn-close-pop:hover{
        color: white;
        border:solid 2px red;
        background: red;
        cursor: pointer;
    }


    /*ROW 2 */

    .amw-pop-content-row-2{
        display: flex;
        justify-content: flex-start;
        padding-right: 20px;
        padding-left: 20px;
        height: auto;
        margin-bottom: 10px;
        align-items: center;
        flex-direction: column;
    }
    .comp-add-wallet{
        width: 100%;
        margin: 0;
        padding: 10px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #aaaaaa33;
        border:none;
        flex-direction: column !important;
        display: flex !important;
    }
    .comp-add-wallet .token-add{
        align-items: flex-end;
    }

    .token-add-col-1{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .token-add-search{
        display: flex;
        width: 100%;
        align-items: center;
    }
    .select-add-wallet div{
        padding-left: 10px;
    }

    .valueinath{
        padding: 0 5px !important;
        width: 100% !important;
    }

    .token-add-col-2{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .add-token-amount{
        display: flex;
        align-items: center;
        font-size: 16px;
    }
    .add-token-amount .token-input-add{
        font-size: 18px;
        font-weight: 500;
        border-color: #00000033;
        border-width: 1px;
        border-radius: 5px;
        height: 35px;
        padding-right: 10px;
    }



    .token-add-col-3{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;
    }
    .token-add-col-3 .button-add{
        width: 100%;
        height: 39px;
    }

    .amw-pop-content-row-3{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        width: 92%;
        max-height: 50vh;
        min-height: 50vh;
    }
    .amw-pop-content-row-3 .comp-wallet-title{
        display: none;
    }
    .amw-pop-content-row-3 .my-wallet-scroll{
        margin: 0 !important;
        width: 100% !important;
        padding: 0 !important;
        max-height: 42vh !important;
        min-height: 42vh !important;
        max-width: 100% !important;
    }
    .amw-pop-content-row-3 .comp-my-wallet{
        display: grid;
        margin: 0 !important;
        margin-top: 5px !important;
        width: 99%;
        grid-template-columns: 4% 26% 18% 18% 10% 20% 4% !important;
        align-items: center;
        padding: 5px;
    }

    .amw-pop-content-row-3 .fa-trash{
        font-size: 30px;
    }
    .amw-pop-content-row-3 .fa-trash:hover{
        color:red;
        cursor: pointer;
    }

    .amw-pop-content-row-4{
        display: flex;
        grid-template-columns: 35% 40%;
        grid-template-rows: 100%;
        justify-content: space-between;
        height: 70px;
        max-height: 70px;
        min-height: 50px;
        background-color: white;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        flex-direction:column-reverse;
    }
    .amw-pop-content-row-4 .comp-total {
        padding: 0;
        margin: 0;
        margin-right: 20px !important;
        margin-left: 20px !important;
        display: flex !important;
        flex-direction: column !important;
        width: 100%;
        align-items: flex-start;
    }
    .amw-pop-content-row-4 .comp-total span{
        margin-left: 10px !important;
        margin-right: 10px !important;
        font-size: 20px;
    }
    .amw-pop-content-row-4 .comp-total b{
        margin-left: 10px !important;
        margin-right: 10px !important;
        text-align: right;
        width: 90%;
        font-size: 34px;
        margin-top: 0 !important;
        margin-bottom: 10px;
    }
    .amw-pop-content-row-4 .comp-save{
        height: auto !important;
        margin: 0 !important;
        
    }
    
    .cripto-names.completo{
        display: flex;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        
    }
    .cripto-name{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .div-wallet-crypto {
        padding-right: 10px;
    }
    .amw-pop-content-row-3 .my-wallet-scroll{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    
    .mwp-mobile{
        display: flex;
        flex-direction: column;
        margin:5px 0px !important;
        padding: 0 !important;
        width: 100% !important;
        border-radius: 10px !important;
    }
    .mwp-mobile .mobile-section-1{
        justify-content: flex-start !important;
        width: calc(100% - 20px) !important;
        margin: 0px 10px !important;
        align-items: center !important;
        min-height: 40px !important;
        border-radius: 10px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .mwp-mobile .mobile-section-1 .favorite{
        margin-left: 10px !important;
    }
    .mwp-mobile .mobile-section-2{
        width: calc(100% - 20px) !important;
        margin: 0px 10px !important;
        align-items: center !important;
        border-radius: 10px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .mwp-mobile .mobile-section-2 .div-names-crypto input{
        min-width: 100% !important;
    }
    .mwp-mobile .mobile-section-2 .div-names-crypto{
        min-width: 80% !important;
    }
    .grafica-svg{
        max-width: 70%;
    }
}