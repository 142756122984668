/*COMPONENTE CALCULATOR*/
.comp-calculator{
    background-color: #fff;
    width: 100vw !important;
    max-width: 1440px;
    height: 150px;
    margin-bottom: 100px;
    margin-top: 30px;
}
.section-calculator{
    background-color: white;
    margin: 0 40px;
    padding: 20px;
    padding-left: 50px;
    border-radius: 10px;
    border:solid 2px #00000055;
    box-shadow: 0px 0px 20px 2px #00000055;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'FontAwesome',"Poppins";
}
input#react-select-3-input{
    padding-left: 20px !important;
   
    
}
#react-select-3-listbox div{
    background: white !important;
    color: black !important;
    
}
#react-select-3-listbox div:hover{
    background: #008100 !important;
    color: white !important;
    cursor: pointer;
}
.column-calculator{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 10px;
    width: auto;
    height: 75px;
}
.column-calculator > span{
    font-weight: 600;
    font-size: 18px;
}
/*BUSCADOR CALCULATOR*/
.search-calculator{
    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.search-calculator > img{
    position: relative;
    margin-right: -25px;
    z-index: 1;
}
.select-calculator{
    width: 250px;
    text-align: right;
    white-space: pre-wrap;
    text-overflow: ellipsis;
}
/*se necesita darle padding al input*/


/*EDITOR USD CALCULATOR*/

.calc-i{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dollar-calculator{
    position: relative;
    margin-right: -25px;
}

.edit-calculator{
    position: relative;
    margin-left: -25px;
    color: #00000099;
}
.calc-input{
    height: 30px;
    border:solid 1px #00000022;
    border-radius: 10px;
    width: 200px;
    padding-right: 30px;
    font-size: 20px;
    font-weight: 600;
    color:#00000099;
    text-align: right;
    
}

/*TOKENS CALCULATOR*/
div.calc-token{
    width: 200px;
}
span.calc-token{
    font-size: 24px;
}

/*ATH CALCULATOR*/
span.calc-ath{
    font-size: 24px;
}
/*TOTAL CALCULATOR*/
div.calc-total{
    width: 200px;
}
span.calc-total{
    font-size: 24px;
    color:#008100;
    font-weight: bold;
}