

/*COMP SWAP ATH*/

.comp-swap-ath{
    width: 100vw;
    max-width: 1440px;
    
}
/*SAWP SELECTOR */
.comp-selector-swap{
    width: 100%;
    display: flex;
    margin: 0 40px;

}
.swap-input{
    width: 250px;
    height: 34px;
    padding-left: 20px;
    position: relative;
    z-index: 1;
    border: solid 1px #00000033;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: right;
    font-size: 18px;
}
.search-name-swap{
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 0px;
    z-index: 2;
}
.select-swap{
    width: 120px;
    text-align: right !important;
    border: none;
}
.img-swap{
    position: relative;
    margin-right: -25px;
    z-index: 3;
}

/**/



/*TITULOS ATH SWAP*/

.border-swap-left{
    display: flex;
    justify-content: center;
}
.border-swap-left-2{
    border-left: solid 2px #b3b3b3;
    display: flex;
    justify-content: center;
    height: 100%;
}
.div-swap-index{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}
.div-swap-crypto{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.div-swap-current{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.div-swap-ath{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.div-swap-high{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.div-swap-date{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.div-swap-drop{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.div-swap-total{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}



.comp-swap-title{
    color: white;
    margin: 10px 40px;
    padding: 10px 0px;
    border-radius: 20px;
    background-color: #008100;
    display:grid;
    grid-template-columns: 4% 20% 12% 12% 10% 10% 15% 17%;
}
/*ATH ROW*/

.comp-swap-row{
    margin: 10px 40px;
    padding: 10px 10px;
    height: 50px;
    align-items: center;
    border: solid 1px #00000099;
    background-color: white;
    border-radius: 10px;
    display:grid;
    grid-template-columns: 4% 20% 12% 12% 10% 10% 15% 17%;
}

.comp-swap-section{
    background-color: #F2F2F2;
    color: black;
   
    margin-top: 20PX;
    padding: 10px 0px;
    
}
.comp-swap-scroll-rows{
    overflow-x: hidden;
    height: 600px;
}


.div-index-swap{
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
}
.div-crypto-swap{
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    column-gap: 5px;
    align-items: center;
}
.cripto-name-swap{
    font-weight: 500;
    color: #3f4765;
}

.cripto-sufijo-swap{
    font-weight: 500;
    color: gray;
}
.div-current-swap{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    text-align: right;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
}
.div-ath-swap{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    text-align: right;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
}
.div-high-swap{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
}
.div-date-swap{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}
.div-drop-swap{
    display: flex;
    justify-content: center;
    padding-right: 10px;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}
.div-total-swap{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    text-align: right;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    color: green;
}









.div-center{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.div-left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align:left;
}
.div-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}
.div-center span{
  width: 100%;
  text-align: center;
}

.div-star,.div-index{
    width: 3vw;
}
.div-criptoData{
    width: 15vw;
}
.div-currentPrice,.div-athPrice,.div-toHighPorcent,.div-dropPorcent,.div-volume24{
    width: 10vw;
}
.div-dataAth{   
    width: 15vw;
}
.comp-athRow .div-toHighPorcent{
    background-color: green;
    border-radius: 5px;
    color:white;
    font-size: 14px;
    height: 50%;
    font-weight: 600;
}
.comp-athRow .div-dropPorcent{
    background: white;
    border-radius: 5px;
    color:#666666;
    font-size: 14px;
    height: 50%;
    font-weight: 600;
    border: solid 1px red;
    z-index: 1;
    
}

.comp-athRow-buttons button{
    background: rgb(239, 242, 245);
    padding: 4px 10px;
    border-radius: 8px;
    font-weight: 600;
    border:solid 1px #00000022
}

.div-criptoData{
    display : flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
}
.div-criptoData .cripto-logo-coin{
    display : flex;
    align-items: center;
    justify-content: center;
}
.div-criptoData .cripto-names{
    display : flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction:column;
    font-size: 14px;
    font-weight: 500;
    color:orangered;
}
.div-criptoData .cripto-name{
    display : flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction:column;
    font-size: 14px;
    font-weight: 500;
    color:gray;
}
.div-volume24{
    color:green;
    font-weight: bold;
}

.pop-search{
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: #00000055;
    justify-content: center;
    align-items: center;
}
.pop-content{
    display: flex;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}
.div-seach-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div-search-item {
    display: flex;
    justify-content: flex-start;
    padding-left: 50px;
    height: 40px;
}
.div-search-item{
    background: #eee;
    border-radius: 5px;
    border:solid 1px #eee;
    margin-bottom: 2px;
}
.div-search-item:hover{
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
}
.div-seach-form input{
   height: 20px;
   font-size: 16px;
   width: 80%;
   margin-bottom: 10px;
}
.div-seach-form button{
    height: 20px;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
    background: blueviolet;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    border-radius: 10px;
    border:solid 1px #888;
    color: white;
    font-weight: 500;
    cursor: pointer;
 }
 .div-dropPorcent button{
    cursor: pointer;
 }