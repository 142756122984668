
/*RESPONSIVE PHONE MAX WIDTH 420PX*/
@media only screen and (max-width:480px){

    .comp-since-ath{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    margin: 40px 0px;
    width: 100vw;
    max-width: 1440px;
    }

    .div-sinces{
    width: 95%;
    display:grid;
    grid-template-columns: 100%;
    justify-content: space-between;
    margin: 0 40px;
    }
    .element-ranking{
    background-color: #fff ;
    display: flex;
    width:100%;
    justify-content: flex-start;
    margin-bottom: 20px;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    column-gap: 10px;
    border-top: solid 1px #00000022;
    border-bottom: solid 1px #00000022;
    }
    .element-ranking:nth-child(2n){
    background-color: #00000022;
    }
    .selected-since{
    margin: 10px;
    height: 30px;
    background-color: #008100;
    color: white;
    font-size: 20px;
    border: solid 2px #00000022;
    cursor: pointer;
    }
    .no-selected-since{
    margin: 10px;
    height: 30px;
    background-color: #fff;
    color: #00000066;
    font-size: 20px;
    border: solid 1px #00000066;
    cursor: pointer;
    }
    .div-since-buttons{
    margin-bottom: 20px;
    width: 95%;
    display: flex;
    justify-content: flex-end;
    }
    .button-load-more{
    margin: 10px;
    height: 30px;
    background-color: #00000033;
    color: black;
    font-size: 18px;
    font-weight: 500;
    border: solid 1px #00000033;
    cursor: pointer;
    }

    .load-more-section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    }
    .last-update{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    column-gap: 10px;
    height: 50px;
    width: 95%;
    }



    .logo-name{
    display: flex;
    flex-direction: column;
    width: 10%;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 30px;
    }
    .derecha{
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
    .abajo{
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    }
    .price-value{
    color:green;
    font-weight: bold;
    font-size: 14px;
    }
    .ath-value{
    color:black;
    font-weight: bold;
    font-size: 14px;
    }
    .arriba{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    }


    .since-x{
    color:gray;
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 10px;
    }
    .porcent .line{
    height: 5px;
    width: 50%;
    background-color: green;
    }
    .porcent .circle{
    height: 20px !important;
    width: 20px !important;
    border-radius: 50%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    margin-top: -12px;
    margin-left: 50%;;
    }
    .porcent .inner-circle{
    position: relative;
    height: 10px !important;
    width: 10px !important;
    border-radius: 50%;
    background-color: white;
    margin-top: 0px;
    margin-left: 0%;
    }
    .high-porcent span{
    color:green;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 30px;
    }
    .high-porcent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    align-items: flex-start;
    height: 100%;
    }


    .porcent{
    height: 5px;
    width: 100%;
    background-color: white;

    }
    .go-bottom{
    height: 50px;
    width: 50px;
    background-color: green;
    position: fixed;
    bottom: 75px;
    right: 20px;
    z-index: 1000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    }


    .element-ranking:before{
        display: none;
    }
    .element-ranking:nth-child(1n){
        background-color:#fff;
    }
    .element-ranking:nth-child(2n){
        background-color:#00000022;
    }

}