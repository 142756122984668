.comp-title{
    width: 100vw !important;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
.section-title{
    margin: 0px 50px;
    margin-top: 0px;
    font-weight: 600;
    font-size: 24px;
    text-transform: capitalize;
    
}
.section-title img{
    margin-right: 10px;
}
.section-subtitle{
    margin: 0px 50px;
    margin-bottom: 20px;
}
.icon-information sg{
    color:"#ccc" !important;
}
.icon-information:hover {
    color:"green" !important;
}