#footer{
    background-color: #4d4d4d;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    
    max-width: 1440px !important;
}
#footer a{
    text-decoration: none;
    color:white;
}
.parrafo-01{
    font-size: 14px;
}

.footer-row.row1{
    display: flex;
    justify-content: space-between;
    background-color: black;
    width: 100%;
}



.footer-inner-row{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px 40px;
}
.footer-column .social-media{
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 10px;
    cursor: pointer;
    
}
.footer-column .social-media img{
    opacity: 0.9;
}

.footer-column .social-media img:hover{
    opacity:1 ;
}

.footer-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0px;
}

.footer-column.column-1{
    width: 60%;
    margin-top: 10px;
}
.footer-column.column-3{
    width: auto;
    width: 15%;
    margin-top: 20px;
}
.footer-column.column-4{
    width:auto;
    width: 15%;
    margin-top: 20px;
}
.footer-column.column-1 img{
   height: 70px;
}


/*ROW 2 FOOTER*/
.footer-row.row2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}

.footer-row.row2 span{
    margin-left: 35px;
}