:root {
  --c-primary: #ffffff;
  --c-secundary: #000000;
  --c-white-1: #ffffff;
  --c-black-1: #000000;
  --c-tercero: rgb(0,129,1);
  --c-cuarto:rgba(0,112,37,1);
  --c-quinto:rgba(0,104,55,1);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
